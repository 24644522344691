<template>
  <div class="flex-layout" :data-noscroll="$store.state.overlayPanel?true:false" :data-offset="!!$store.state.helpPanel" :data-guided="$store.state.guidedMode" :data-show-links="$store.state.showLinks">

      <div class="flex-screens" ref="screens">
        <slot name="screens"></slot>
      </div>



      <div class="flex-pullouts" v-if="$store.state.pulloutPanel">

        <slot name="pullouts"></slot>
      </div>

      <notifications-pullout class="nav-panel" v-if="$store.state.overlayPanel == 'notifications'" @close="$screens.setOverlayPanel('')"></notifications-pullout>
      <news-pullout class="nav-panel" v-if="$store.state.overlayPanel == 'news'" @close="$screens.setOverlayPanel('')"></news-pullout>
      <people-pullout class="nav-panel" v-if="$store.state.overlayPanel == 'people'" @close="$screens.setOverlayPanel('')"></people-pullout>

      <FlexNavTopBar :offset="!!$store.state.helpPanel"></FlexNavTopBar>
      <FlexAdminSideNav v-if="environment === 'admin'" ></FlexAdminSideNav>
      <FlexEmployeeSideNav v-if="environment === 'employee'" :lang="lang"></FlexEmployeeSideNav>


      <help-pullout v-if="$store.state.helpPanel" @close="$screens.closeHelp"></help-pullout>
    </div>


</template>

<script>
import FlexAdminSideNav from "@/components/FlexAdminSideNav";
import FlexEmployeeSideNav from "@/components/FlexEmployeeSideNav";
import FlexNavTopBar from "@/components/FlexNavTopBar";
import HelpPullout from "@/components/HelpPullout";
import NewsPullout from "@/components/NewsPullout";
import NotificationsPullout from "@/components/NotificationsPullout";
import PeoplePullout from "@/components/PeoplePullout";



export default {
  name: "FlexLayout",
  components: {

    PeoplePullout,
    NotificationsPullout, NewsPullout, HelpPullout, FlexNavTopBar, FlexAdminSideNav, FlexEmployeeSideNav },
  props: {
    environment: {
      type: String,
      default: "admin"
    },
    lang: {
      type: String,
      default: "en"
    }
  },
  mounted() {
    this.$store.state.screenScrollRef = this.$refs["screens"];
    if(this.$route.query){
      this.$store.state.pulloutPanel = this.$route.query.pullout?this.$route.query.pullout:"";
      this.$store.state.overlayPanel = this.$route.query.tab?this.$route.query.tab:"";
    }
  },
  data: function(){
    return {

    }
  },
  methods: {

  },

  computed: {
  }

}
</script>

<style scoped>

  .flex-layout {
    width: 100%;
    position: absolute;
    height: 0;
    overflow: hidden;
    padding-bottom: calc((920/1580) * 100%);
    background-repeat: repeat-y;
    background-image: url(../assets/screen-bkg.png);
    background-size: 100% 10%;
    font-size: 1em;
    box-sizing: border-box;
    max-width: 1580px;

  }



  .flex-sidenav {
    background-color: white;
  }


  .flex-screens {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    overflow-y:scroll;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {

  }






  .flex-pullouts {
    position: absolute;
    width: 100%;
    height: 100%;

  }


  [data-offset = "true"] .flex-screens {
    transform: translateX(calc((-180 / 1580) * 100%));
  }

  [data-offset = "true"] .flex-pullouts {
    width: 100%;
    transform: translateX(calc((-320 / 1580) * 100%));
  }

  [data-offset = "true"] .nav-panel {
    transform: translateX(calc((-320 / 1580) * 100%));
  }



  [data-noscroll="true"]{
    overflow-y: hidden;
  }



  @media(min-width:1580px){

  }

  @media(min-width: 1920px){


  }


</style>