


<template>
<div class="">


  <div class="panel-content">


    <div class="help-scroll" v-if="detailPanel" ref="scrollPanel">
      <div v-if="$store.state.company === 'pro'" class="detail-content" data-screen="profile" :data-active="detailPanel=='profile'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pro-andrea.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="5%" top="39%" width="90%" height="7%" data-tgt="people-andrea" dot-class="dot-right-center" @click.native="onLinkClick"></screen-link>
          <screen-link left="5%" top="50%" width="90%" height="6%" data-tgt="profile-details" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="73.5%" width="90%" height="6%" data-tgt="pay-compensation" dot-class="dot-right-center" @click.native="showDetails"></screen-link>

          <screen-link left="5%" top="61.5%" width="90%" height="6%" data-tgt="employment" dot-class="dot-right-center" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div v-else class="detail-content" data-screen="profile" :data-active="detailPanel=='profile'" >
        <img class="w-full" src="/img/screens/people/pullout-people-andrea.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="5%" top="39%" width="90%" height="7%" data-tgt="people-andrea" dot-class="dot-right-center" @click.native="onLinkClick"></screen-link>
          <screen-link left="5%" top="49%" width="90%" height="8%" data-tgt="profile-details" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="72.3%" width="90%" height="8%" data-tgt="pay-compensation" dot-class="dot-right-center" @click.native="showDetails"></screen-link>

          <screen-link left="5%" top="107.5%" width="90%" height="8%" data-tgt="availability" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="118.5%" width="90%" height="8%" data-tgt="time-off" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="130.25%" width="90%" height="8%" data-tgt="conversations" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="176.5%" width="90%" height="8%" data-tgt="documents" dot-class="dot-right-center" @click.native="showDetails"></screen-link>

          <screen-link left="5%" top="60.75%" width="90%" height="8%" data-tgt="employment" dot-class="dot-right-center" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="employment" :data-active="detailPanel=='employment'" >
        <img class="w-full" src="/img/screens/people/people-profile-employment.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="1%" width="25%" height="4%" data-tgt="profile" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="2%" top="11%" width="10%" height="4%" data-tgt="employment" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="26%" top="11%" width="10%" height="4%" data-tgt="employment-i9" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="employment-i9" :data-active="detailPanel=='employment-i9'" >
        <img class="w-full" src="/img/screens/people/people-profile-employment-i9.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="1%" width="25%" height="4%" data-tgt="profile" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="2%" top="11%" width="10%" height="4%" data-tgt="employment" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="26%" top="11%" width="10%" height="4%" data-tgt="employment-i9" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="38%" top="48%" width="25%" height="5%" data-tgt="employment-i9-assign" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="employment-i9-assign" :data-active="detailPanel=='employment-i9-assign'" >
        <img class="w-full" src="/img/screens/people/people-profile-employment-i9-assign.png?v=10122022" />
        <div class="links">
          <screen-link left="67%" top="1%" width="15%" height="4%" data-tgt="employment-i9" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="6%" top="37%" width="43%" height="14%" data-tgt="employment-i9-assign-you" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="employment-i9-assign-you" :data-active="detailPanel=='employment-i9-assign-you'" >
        <img class="w-full" src="/img/screens/people/people-profile-employment-i9-assign-you.png?v=10122022" />
        <div class="links">
          <screen-link left="67%" top="1%" width="15%" height="4%" data-tgt="employment-i9" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="60%" width="43%" height="4%" data-tgt="employment-i9-assign-select" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="employment-i9-assign-select" :data-active="detailPanel=='employment-i9-assign-select'" >
        <img class="w-full" src="/img/screens/people/people-profile-employment-i9-assign-select.png?v=10122022" />
        <div class="links">
          <screen-link left="5%" top="51%" width="43%" height="4%" data-tgt="employment-i9-assign-select-flannery" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="64%" top="92%" width="15%" height="5%" data-tgt="employment-i9-assign" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="employment-i9-assign-select-flannery" :data-active="detailPanel=='employment-i9-assign-select-flannery'" >
        <img class="w-full" src="/img/screens/people/people-profile-employment-i9-assign-select-flannery.png?v=10122022" />
        <div class="links">
          <screen-link left="5%" top="51%" width="43%" height="4%" data-tgt="employment-i9-assign-select-flannery" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="64%" top="92%" width="15%" height="5%" data-tgt="employment-i9-assign" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
          <screen-link left="79%" top="92%" width="16%" height="5%" data-tgt="employment-i9-assign-selected-flannery" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="employment-i9-assign-selected-flannery" :data-active="detailPanel=='employment-i9-assign-selected-flannery'" >
        <img class="w-full" src="/img/screens/people/people-profile-employment-i9-assign-selected-flannery.png?v=10122022" />
        <div class="links">
          <screen-link left="67%" top="1%" width="15%" height="4%" data-tgt="employment-i9" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="83%" top="1%" width="15%" height="4%" data-tgt="employment-i9-saved" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="employment-i9-saved" :data-active="detailPanel=='employment-i9-saved'" >
        <img class="w-full" src="/img/screens/people/people-profile-employment-i9-saved.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="1%" width="25%" height="4%" data-tgt="profile" dot-class="dot-right-center" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="profile-details" :data-active="detailPanel=='profile-details'" >
        <img class="w-full" src="/img/screens/people/pullout-people-andrea-details.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="1%" width="25%" height="4%" data-tgt="profile" dot-class="dot-right-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="conversations" :data-active="detailPanel=='conversations'" >
        <img class="w-full" src="/img/screens/people/pullout-people-conversations.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="77%" top="5.75%" width="21%" height="5%" data-tgt="conversations-add-note" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="conversations-add-note" :data-active="detailPanel=='conversations-add-note'" >
        <img class="w-full" src="/img/screens/people/pullout-people-conversations-add-note.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="conversations" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="availability" :data-active="detailPanel=='availability'" >
        <img class="w-full" src="/img/screens/taa/pullouts/pullover-timeoff-availability.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="time-off" :data-active="detailPanel=='time-off'" >
        <img class="w-full" src="/img/screens/taa/pullouts/pullover-timeoff.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off" @click.native="showDetails"></screen-link>
          <screen-link left="15%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-requests" @click.native="showDetails"></screen-link>
          <screen-link left="28%" top="11%" width="19.5%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-accrual" @click.native="showDetails"></screen-link>
          <screen-link left="47.5%" top="11%" width="16%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-policy" @click.native="showDetails"></screen-link>
          <screen-link left="63.5%" top="11%" width="25%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-non-taa" @click.native="showDetails"></screen-link>



        </div>
      </div>

      <div class="detail-content" data-screen="time-off-requests" :data-active="detailPanel=='time-off-requests'" >
        <img class="w-full" src="/img/screens/taa/pullouts/pullover-timeoff-requests-upcoming.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off" @click.native="showDetails"></screen-link>
          <screen-link left="15%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-requests" @click.native="showDetails"></screen-link>
          <screen-link left="28%" top="11%" width="19.5%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-accrual" @click.native="showDetails"></screen-link>
          <screen-link left="47.5%" top="11%" width="16%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-policy" @click.native="showDetails"></screen-link>

          <screen-link left="65%" top="24%" width="15%" height="4.5%" dot-class="dot-top-center" data-tgt="time-off-requests" @click.native="showDetails"></screen-link>
          <screen-link left="80%" top="24%" width="14%" height="4.5%" dot-class="dot-top-center" data-tgt="time-off-requests-history" @click.native="showDetails"></screen-link>
          <screen-link left="63.5%" top="11%" width="25%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-non-taa" @click.native="showDetails"></screen-link>




        </div>
      </div>

      <div class="detail-content" data-screen="time-off-requests-history" :data-active="detailPanel=='time-off-requests-history'" >
        <img class="w-full" src="/img/screens/taa/pullouts/pullover-timeoff-requests-history.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off" @click.native="showDetails"></screen-link>
          <screen-link left="15%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-requests" @click.native="showDetails"></screen-link>
          <screen-link left="28%" top="11%" width="19.5%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-accrual" @click.native="showDetails"></screen-link>
          <screen-link left="47.5%" top="11%" width="16%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-policy" @click.native="showDetails"></screen-link>

          <screen-link left="65%" top="24%" width="15%" height="4.5%" dot-class="dot-top-center" data-tgt="time-off-requests" @click.native="showDetails"></screen-link>
          <screen-link left="80%" top="24%" width="14%" height="4.5%" dot-class="dot-top-center" data-tgt="time-off-requests-history" @click.native="showDetails"></screen-link>
          <screen-link left="63.5%" top="11%" width="25%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-non-taa" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="time-off-accrual" :data-active="detailPanel=='time-off-accrual'" >
        <img class="w-full" src="/img/screens/taa/pullouts/pullover-timeoff-accrual.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off" @click.native="showDetails"></screen-link>
          <screen-link left="15%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-requests" @click.native="showDetails"></screen-link>
          <screen-link left="28%" top="11%" width="19.5%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-accrual" @click.native="showDetails"></screen-link>
          <screen-link left="47.5%" top="11%" width="16%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-policy" @click.native="showDetails"></screen-link>
          <screen-link left="63.5%" top="11%" width="25%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-non-taa" @click.native="showDetails"></screen-link>



        </div>
      </div>

      <div class="detail-content" data-screen="time-off-policy" :data-active="detailPanel=='time-off-policy'" >
        <img class="w-full" src="/img/screens/taa/pullouts/pullover-timeoff-policyhistory.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off" @click.native="showDetails"></screen-link>
          <screen-link left="15%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-requests" @click.native="showDetails"></screen-link>
          <screen-link left="28%" top="11%" width="19.5%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-accrual" @click.native="showDetails"></screen-link>
          <screen-link left="47.5%" top="11%" width="16%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-policy" @click.native="showDetails"></screen-link>
          <screen-link left="63.5%" top="11%" width="25%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-non-taa" @click.native="showDetails"></screen-link>



        </div>
      </div>

      <div class="detail-content" data-screen="time-off-non-taa" :data-active="detailPanel=='time-off-non-taa'" >
        <img class="w-full" src="/img/screens/taa/pullouts/pullover-timeoff-non-taa.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off" @click.native="showDetails"></screen-link>
          <screen-link left="15%" top="11%" width="13%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-requests" @click.native="showDetails"></screen-link>
          <screen-link left="28%" top="11%" width="19.5%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-accrual" @click.native="showDetails"></screen-link>
          <screen-link left="47.5%" top="11%" width="16%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-policy" @click.native="showDetails"></screen-link>
          <screen-link left="63.5%" top="11%" width="25%" height="4%" dot-class="dot-bottom-center" data-tgt="time-off-non-taa" @click.native="showDetails"></screen-link>

          <coming-soon label="Enterprise bundle includes Time Off Accruals with optional upgrade for requests and approvals."/>



        </div>
      </div>

      <div class="detail-content" data-screen="documents" :data-active="detailPanel=='documents'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="77%" top="5.75%" width="21%" height="5%" data-tgt="documents-actions" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="documents-actions" :data-active="detailPanel=='documents-actions'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-actions.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="77%" top="5.75%" width="21%" height="5%" data-tgt="documents" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

          <screen-link left="70%" top="12%" width="21%" height="4%" data-tgt="documents-add" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="70%" top="17.5%" width="27%" height="4%" data-tgt="documents-request" dot-class="dot-left-center" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="documents-add" :data-active="detailPanel=='documents-add'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-add.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="documents" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="documents-request" :data-active="detailPanel=='documents-request'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="documents" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="28%" width="90%" height="4%" data-tgt="documents-request-title" @click.native="showDetails"></screen-link>
        </div>
      </div>


      <div class="detail-content" data-screen="documents-request-title" :data-active="detailPanel=='documents-request-title'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request-title.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="documents" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="51%" width="90%" height="5%" data-tgt="documents-request-categories" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="documents-request-categories" :data-active="detailPanel=='documents-request-categories'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request-categories.png?v=10122022" />
        <div class="links">

          <screen-link left="80%" top="71%" width="16%" height="5.5%" data-tgt="documents-request-title" @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="59%" width="80%" height="5%" data-tgt="documents-request-details" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="documents-request-details" :data-active="detailPanel=='documents-request-details'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request-details.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="documents" @click.native="showDetails"></screen-link>
          <screen-link left="82.5%" top="0.5%" width="15%" height="5.5%" data-tgt="documents-request-added" dot-class="dot-right-center"   @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="documents-request-added" :data-active="detailPanel=='documents-request-added'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request-added.png?v=10122022" />
        <div class="links">

          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="showDetails"></screen-link>
        </div>
      </div>



      <div class="detail-content" data-screen="pay-compensation" :data-active="detailPanel=='pay-compensation'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pay-compensation.png?v=10022023" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="9%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="9%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="9%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="205%" width="30%" height="6%" dot-class="dot-right-center" data-tgt="reports-pay-range" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="pay-history" :data-active="detailPanel=='pay-history'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pay-history.png?v=10022023" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="9%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="9%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="4%" top="23%" width="92%" height="8.5%" data-tgt="pay-history-details" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="9%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="pay-history-details" :data-active="detailPanel=='pay-history-details'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pay-history-details.png?v=10022023" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="pay-history" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="78%" top="7%" width="19%" height="5%" data-tgt="pay-history-details-edit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="pay-history-details-edit" :data-active="detailPanel=='pay-history-details-edit'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pay-history-details-edit.png?v=10022023" />
        <div class="links">
          <screen-link left="67%" top="3%" width="16%" height="5%" dot-class="dot-bottom-center" data-tgt="pay-history-details" @click.native="showDetails"></screen-link>

        </div>
      </div>


      <div class="detail-content" data-screen="pay-deposit" :data-active="detailPanel=='pay-deposit'" >
        <img class="w-full" src="/img/screens/pullout-people-pay-deposit.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="9%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="9%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="4%" top="55%" width="92%" height="6%" data-tgt="pay-deposit-add" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="9%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>
      <div class="detail-content" data-screen="pay-deposit-add" :data-active="detailPanel=='pay-deposit-add'" >
        <img class="w-full" src="/img/screens/pullout-people-pay-deposit-add.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="9%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="9%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="4%" top="31%" width="48%" height="7%" data-tgt="pay-deposit-add-hsbc" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="68%" top="17%" width="14%" height="5%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="9%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="pay-deposit-add-hsbc" :data-active="detailPanel=='pay-deposit-add-hsbc'" >
        <img class="w-full" src="/img/screens/pullout-people-pay-deposit-add-hsbc.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="9%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="9%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="68%" top="17%" width="14%" height="5%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="83%" top="17%" width="14%" height="5%" data-tgt="pay-deposit-hsbc-added" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="9%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="pay-deposit-hsbc-added" :data-active="detailPanel=='pay-deposit-hsbc-added'" >
        <img class="w-full" src="/img/screens/pullout-people-pay-deposit-hsbc-added.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="9%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="9%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="9%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="retention" :data-active="detailPanel=='retention'" >
        <img class="w-full" src="/img/screens/people/pullout-people-retention-insights.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="3%" top="76%" width="94%" height="10%" dot-class="dot-right-center" data-tgt="reports-retention" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="3%" top="52.5%" width="94%" height="7%" dot-class="dot-right-center" data-tgt="pay-raise" data-tgt-scrolltop="true" @click.native="$screens.showHelp"></screen-link>
          <screen-link left="3%" top="59.5%" width="94%" height="7%" dot-class="dot-right-center" data-tgt="low-pay" data-tgt-scrolltop="true" @click.native="$screens.showHelp"></screen-link>
          <screen-link left="3%" top="66.5%" width="94%" height="7%" dot-class="dot-right-center" data-tgt="commute" data-tgt-scrolltop="true" @click.native="$screens.showHelp"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="performance" :data-active="detailPanel=='performance'" >
        <img class="w-full" src="/img/screens/people/pullout-people-performance-active.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>


        </div>
      </div>


      <div class="detail-content" data-screen="invite" :data-active="detailPanel=='invite'" >
        <img class="w-full" src="/img/screens/pullout-people-invites.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>


        </div>
      </div>


      <div class="detail-content" data-screen="add-employee" :data-active="detailPanel=='add-employee'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/people-add-employee.png?v=10122022" />
        <div class="links">


          <company-tab-onboarding top="42.2%" left="8%" width="50%" @select="setCompany" :company="$store.state.onboardingType" :show-links="true" variant="hiring"/>
          <bubble-message pointing="left" :styles="{left:'57%',top:'39.5%',width:'25%'}">Use the company selector to access PEO Onboarding.</bubble-message>

          <screen-link left="89%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="5%" top="62%" width="90%" height="6%" data-tgt="add-employee-details" data-dont-scroll="true" @click.native="showDetails"></screen-link>



        </div>
      </div>

      <div class="detail-content" data-screen="add-employee-peo" :data-active="detailPanel=='add-employee-peo'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/people-add-employee-peo.png?v=10122022" />
        <div class="links">

          <screen-link left="91%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="5%" top="37%" width="90%" height="6%" data-tgt="add-employee-details-peo" data-dont-scroll="true" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="add-employee-details" :data-active="detailPanel=='add-employee-details'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/people-add-employee-details.png?v=10122022" />
        <div class="links">

          <company-tab-static top="42.2%" left="8%" width="50%" @select="setCompany" company="$store.state.company" :show-links="true" variant="hiring" />
          <screen-link left="89%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="72%" top="122.5%" width="25%" height="6%" dot-class="dot-top-center" data-tgt="hire-tasks" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="add-employee-details-peo" :data-active="detailPanel=='add-employee-details-peo'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/people-add-employee-details-peo.png?v=08212023" />
        <div class="links">

          <screen-link left="91%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="73%" top="202.5%" width="25%" height="6%" dot-class="dot-top-center" data-tgt="add-employee-employment-peo" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="add-employee-employment-peo" :data-active="detailPanel=='add-employee-employment-peo'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/add-employee-employment-peo.png?v=10122022" />
        <div class="links">

          <screen-link left="91%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="3%" top="18%" width="90%" height="70%" dot-class="dot-left-center" data-dont-scroll="true" data-tgt="add-employee-employment-details-peo" @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="91%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="add-employee-details-peo" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="add-employee-employment-details-peo" :data-active="detailPanel=='add-employee-employment-details-peo'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/add-employee-employment-details-peo.png?v=10122022" />
        <div class="links">

          <screen-link left="91%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="72%" top="91%" width="25%" height="5%" dot-class="dot-left-center" data-tgt="hire-tasks-peo" @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="91%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="add-employee-details-peo" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-tasks'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-tasks.png?v=02192024" />
        <div class="links">
          <screen-link left="94%" top="1%" width="5%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="79.5%" top="73.25%" width="16%" height="5.5%" data-tgt="hire-tasks-all" data-dont-scroll="true" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-tasks-all'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-tasks-all.png?v=02192024" />
        <div class="links">
          <screen-link left="94%" top="1%" width="5%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>

          <screen-link left="74%" top="120.5%" width="24%" height="5.5%" data-tgt="hire-personal" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="120.5%" width="24%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>

        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-tasks-peo'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/hire-tasks-peo.png?v=02192024" />
        <div class="links">
          <screen-link left="92%" top="1%" width="5%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="113.5%" width="12%" height="5.5%" data-tgt="add-employee-employment-details-peo" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="113.5%" width="23%" height="5.5%" data-tgt="hire-compensation-peo" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="114%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>

        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-personal'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-personal-info-details.png?v=10122022" />
        <div class="links">
          <screen-link left="90%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="225.5%" width="15%" height="5.5%" data-tgt="hire-tasks" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="225.5%" width="24%" height="5.5%" data-tgt="hire-employment" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-employment'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-personal.png?v=10122022" />
        <div class="links">
          <screen-link left="94%" top="1%" width="5%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="115.5%" width="15%" height="5.5%" data-tgt="hire-personal" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="115.5%" width="23%" height="5.5%" data-tgt="hire-compensation" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="116%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-compensation'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-compensation.png?v=08212023" />
        <div class="links">
          <screen-link left="92%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="118.5%" width="15%" height="5.5%" data-tgt="hire-personal" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="118.5%" width="23%" height="5.5%" data-tgt="hire-taxes" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="119%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-compensation-peo'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-compensation-peo.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="119%" width="15%" height="5.5%" data-tgt="hire-tasks-peo" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="119%" width="23%" height="5.5%" data-tgt="hire-taxes" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="119%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-taxes'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-taxes.png?v=10122022" />
        <div class="links">
          <screen-link left="93%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="100.5%" width="15%" height="5.5%" :data-tgt="($store.state.onboardingType==='peo')?'hire-compensation-peo':'hire-compensation'" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="100.5%" width="23%" height="5.5%" data-tgt="hire-deductions" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="100.5%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-deductions'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-deductions.png?v=10122022" />
        <div class="links">
          <screen-link left="93%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="48%" width="15%" height="5.5%" data-tgt="hire-taxes" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="82.5%" top="48%" width="15%" height="5.5%" data-tgt="hire-i9" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="59%" top="48%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-i9'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-i9.png?v=10122022" />
        <div class="links">
          <screen-link left="93%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="54%" width="15%" height="5.5%" data-tgt="hire-deductions" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="6%" top="24%" width="41%" height="12.5%" data-tgt="hire-i9-company" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="56%" top="54%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-i9-company'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-i9-company.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="60.5%" width="15%" height="5.5%" data-tgt="hire-deductions" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="82.5%" top="60.5%" width="15%" height="5.5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-top-center"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="56%" top="61%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>



    </div>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import ComingSoon from "@/components/ComingSoon";
//import CompanyTabStatic from "@/components/CompanyTabStatic";
//import CompanyTabHiring from "@/components/CompanyTabHiring";
import CompanyTabOnboarding from "@/components/CompanyTabOnboarding";
import CompanyTabStatic from "@/components/CompanyTabStatic";
import BubbleMessage from "@/components/BubbleMessage";
//import CompanyTabOnboarding from "@/components/CompanyTabOnboarding";
export default {
    name: "people-pullout-profile-panel",
  components: {BubbleMessage, CompanyTabStatic, CompanyTabOnboarding, ComingSoon, ScreenLink},
  mounted() {
      //this.$store.state.onboardingType = "enterprise"
  },
  props: {
      detailPanel: {
        default: "profile"
      }
  },
    data: function(){
      return {}
    },


    methods: {
      setCompany: function(company){
        //console.log(company)
        //this.$store.commit("setCompany",company)
        this.$store.state.onboardingType = company;
        if(company === "peo"){
          this.showDetails({
            target: {
              dataset: {
                tgt: "add-employee-peo"
              }
            }
          })
        }else{
          this.showDetails({
            target: {
              dataset: {
                tgt: "add-employee"
              }
            }
          })
        }
      },
      showDetails: function(e){
        this.$emit('change',e);
        if(!e.target.dataset.dontScroll){
          this.$refs.scrollPanel.scrollTo(0,0);
        }

      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      },
      showExternalPage: function(e){
        this.$emit("external", e)
      }

    },
    computed: {}
}
</script>

<style scoped>


.profile-pullout-old {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;


}

.panel-content img {
  display: block;
}

.panel-content {

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

}



.help-scroll {
  position: relative;
  height: 100%;

  overflow-y: scroll;

}

.w-full {
  width: 100%;
}

.links {

}

.detail-content {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
}


.detail-content[data-active="true"] {
  display: block;
}

</style>
