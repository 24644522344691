<template>
  <Transition appear name="fade">
    <div class="bubble-message" :class="mountClass" :style="styles">
      <slot></slot>
      <div class="pointer" :style="pointerStyle"></div>
    </div>
  </Transition>

</template>

<script>

export default {
  name: "BubbleMessage",
  data: function(){
    return {
      mountClass: ""
    }
  },
  props: {
    pointing: {
      default: "left"
    },
    styles: {
      default: ()=>{return {}}
    }
  },

  mounted() {
    setTimeout(()=>{
      this.mountClass = "mounted"
    },100)

  },

  computed: {
    pointerStyle: function(){
      switch (this.pointing){
        case "left": return { right: "100%", top: '50%', marginRight: "-0.5em", marginTop: "-0.5em"}
        case "right": return { left: "100%", top: '50%',marginLeft: "-0.5em", marginTop: "-0.5em"}
        case "top": return { left: "50%", bottom: '100%',marginLeft: "-0.5em", marginBottom: "-0.5em"}
        case "bottom": return { left: "50%", top: '100%',marginLeft: "-0.5em", marginTop: "-0.5em"}
      }

      return { right: "100%", top: '50%'}
    }
  }
}
</script>

<style scoped>
  .bubble-message {
    position: absolute;
      padding: 1em;
      background-color: var(--color-green);
    color: white;
    line-height: 1.3em;
    opacity: 0;
    transform: translateX(2em);
    transition: 0.6s all;

  }

  .pointer {
    position: absolute;
    width: 1em;
    height: 1em;
    transform: rotate(45deg);
    background-color: var(--color-green);
  }

  .mounted {
    opacity: 1;
    transform: translateX(0);
  }


</style>