<template>
  <div class="flock-navigation">
    <div class="flock-menu" :data-active="currentMenu === 'home'">
        <img src="/img/screens/flock/menu/flock-nav-home.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
          <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
          <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
          <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
          <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
          <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
          <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
          <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
        </div>
    </div>

    <div class="flock-menu" :data-active="currentScreen === 'flock-tasks'">
      <img src="/img/screens/flock/menu/flock-nav-tasks.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="22%" top="18%" width="37%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="22%" top="23%" width="52%" height="4%" data-tgt="flock-tasks-employee" @click.native="$screens.onLinkClick" />
      </div>
    </div>

    <div class="flock-menu" :data-active="currentScreen === 'flock-tasks-employee'">
      <img src="/img/screens/flock/menu/flock-nav-tasks-employee.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="22%" top="18%" width="37%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="22%" top="23%" width="52%" height="4%" data-tgt="flock-tasks-employee" @click.native="$screens.onLinkClick" />
      </div>
    </div>

    <div class="flock-menu" :data-active="currentMenu === 'people'">
      <img src="/img/screens/flock/menu/flock-nav-people.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
      </div>
    </div>

    <div class="flock-menu" :data-active="currentMenu === 'benefits'">
      <img src="/img/screens/flock/menu/flock-nav-benefits.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="21%" top="18%" width="35%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="28%" width="24%" height="4%" data-tgt="flock-plans" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="38%" width="56%" height="4%" data-tgt="flock-events" @click.native="$screens.onLinkClick" />


      </div>
    </div>

    <div class="flock-menu" :data-active="currentMenu === 'plans'">
      <img src="/img/screens/flock/menu/flock-nav-benefits-plans.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="21%" top="18%" width="35%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="28%" width="24%" height="4%" data-tgt="flock-plans" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="38%" width="56%" height="4%" data-tgt="flock-events" @click.native="$screens.onLinkClick" />


      </div>
    </div>

    <div class="flock-menu" :data-active="currentMenu === 'events'">
      <img src="/img/screens/flock/menu/flock-nav-benefits-events.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="21%" top="18%" width="35%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="28%" width="24%" height="4%" data-tgt="flock-plans" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="38%" width="56%" height="4%" data-tgt="flock-events" @click.native="$screens.onLinkClick" />


      </div>
    </div>

    <div class="flock-menu" :data-active="currentMenu === 'reports'">
      <img src="/img/screens/flock/menu/flock-nav-reports.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
      </div>
    </div>

    <div class="flock-menu" :data-active="currentMenu === 'documents'">
      <img src="/img/screens/flock/menu/flock-nav-documents.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
      </div>
    </div>

    <div class="flock-menu" :data-active="currentScreen === 'flock-settings'">
      <img src="/img/screens/flock/menu/flock-nav-settings.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="21%" top="22%" width="32%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="27%" width="27%" height="4%" data-tgt="flock-settings-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="32%" width="30%" height="4%" data-tgt="flock-settings-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="50.5%" width="25%" height="4%" data-tgt="flock-settings-roles" @click.native="$screens.onLinkClick" />
      </div>
    </div>

    <div class="flock-menu" :data-active="currentScreen === 'flock-settings-people'">
      <img src="/img/screens/flock/menu/flock-nav-settings-people-notifications.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="21%" top="22%" width="32%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="27%" width="27%" height="4%" data-tgt="flock-settings-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="49%" width="30%" height="4%" data-tgt="flock-settings-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="67.5%" width="25%" height="4%" data-tgt="flock-settings-roles" @click.native="$screens.onLinkClick" />
      </div>
    </div>

    <div class="flock-menu" :data-active="currentScreen === 'flock-settings-benefits'">
      <img src="/img/screens/flock/menu/flock-nav-settings-benefits-notifications.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="21%" top="22%" width="32%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="27%" width="27%" height="4%" data-tgt="flock-settings-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="32%" width="30%" height="4%" data-tgt="flock-settings-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="72.2%" width="25%" height="4%" data-tgt="flock-settings-roles" @click.native="$screens.onLinkClick" />
      </div>
    </div>

    <div class="flock-menu" :data-active="currentScreen === 'flock-settings-roles'">
      <img src="/img/screens/flock/menu/flock-nav-settings-roles.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />

        <screen-link dot-class="dot-right-center" left="21%" top="22%" width="32%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="27%" width="27%" height="4%" data-tgt="flock-settings-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="32%" width="30%" height="4%" data-tgt="flock-settings-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="21%" top="50.5%" width="25%" height="4%" data-tgt="flock-settings-roles" @click.native="$screens.onLinkClick" />
      </div>
    </div>



    <div class="flock-menu" :data-active="currentMenu === 'profile'">
      <img src="/img/screens/flock/menu/flock-nav-profile.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-right-center" left="1%" top="11.25%" width="15%" height="4%" data-tgt="flock-home" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="16.25%" width="15%" height="4%" data-tgt="flock-tasks" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="21.25%" width="15%" height="4%" data-tgt="flock-people" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="26.25%" width="15%" height="4%" data-tgt="flock-benefits" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="31.25%" width="15%" height="4%" data-tgt="flock-reports" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="36.25%" width="15%" height="4%" data-tgt="flock-documents" @click.native="$screens.onLinkClick" />
        <screen-link dot-class="dot-right-center" left="1%" top="78.5%" width="15%" height="4%" data-tgt="flock-language" @click.native="showLanguageOptions" />
        <screen-link dot-class="dot-right-center" left="1%" top="88.6%" width="15%" height="4%" data-tgt="flock-settings" @click.native="$screens.onLinkClick" />
      </div>
    </div>



    <div v-if="showLanguages" class="language-options" @click="showLanguages = false">
      <div class="language-option"><i class="check-icon"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></i> English</div>
      <div class="language-option"><i class="check-icon"></i> Español</div>
    </div>

  </div>
</template>

<script>

import ScreenLink from "@/components/ScreenLink";

export default {
  name: "flock-navigation",
  components: { ScreenLink },
  watch: {
    currentScreen: function (){
      this.showLanguages = false;
    }
  },
  data: function(){
    return {
      showLanguages: false
    }
  },
  props: {
    currentScreen: {
      default: "flock-home"
    }
  },
  methods: {
    showLanguageOptions: function(){
      this.showLanguages = !this.showLanguages;
    }
  },
  
  computed: {
    currentMenu: function(){
      console.log("currentScreen", this.currentScreen)
      if(this.currentScreen){
        return this.currentScreen.split("-")[1];
      }

      return "home"

    }
  }
}
</script>

<style scoped>

  .flock-navigation {
    width: calc((300/1580) * 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
  }

  .flock-menu {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    position: absolute;
    display: none;
  }

  .flock-menu img {
    width: 100%;
    display: block;
  }

  .flock-menu .links {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .flock-menu[data-active="true"]{
    display: block;
  }

  .language-options {
    position: absolute;
    bottom: 5em;
    background: white;
    border-radius: 0.2em;
    margin-left: 0.4em;
    padding-bottom: 0.5em;
    box-shadow: 0 0.7em 1em 0 rgb(0 0 0 / 12%);
  }

  .language-option {
    padding: 0.5em;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    width: 12em;
    user-select: none;
    cursor: pointer;
  }

  .check-icon {

    width: 1em;
    margin-right: 1em;
  }

</style>