


<template>
<div class="people-pullout pullout-panel">
  <div class="bkg"></div>
  <div v-if="showList" class="people-list" style="background: white">
    <div>
      <img class="w-full" src="/img/screens/people/pullout-people-header.png?v=10122022" />
      <div class="links">



        <company-tab-people :company="$store.state.company" select-tgt="" left="4.5%" width="98.75%" />

        <screen-link left="88%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="" @click.native="$emit('close')"></screen-link>
        <screen-link left="78%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="" @click.native="(showOptions = true)"></screen-link>
        <screen-link left="62%" top="1%" width="15%" height="4.5%" dot-class="dot-bottom-center" data-tgt="" @click.native="(showAdd = true)"></screen-link>

      </div>
      <div class="options-panel" v-if="showOptions">
        <screen-link left="85%" top="1%" width="15%" height="20%" dot-class="dot-left-center" data-tgt="" @click.native="(showOptions = false)">
          <svg class="close-icon" viewBox="0 0 14.01 13.91" id="close" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><title>close</title><path d="M8.45 6.95l5.2-5.2a1 1 0 0 0 .1-1.4 1 1 0 0 0-1.4-.1l-.1.1-5.2 5.2-5.2-5.2a1 1 0 0 0-1.4.1 1.05 1.05 0 0 0 0 1.3l5.2 5.2-5.3 5.2a1 1 0 0 0-.1 1.4 1 1 0 0 0 1.4.1l.1-.1 5.3-5.2 5.2 5.2a1 1 0 0 0 1.5-1.3l-.1-.1z" fill="#404040"></path></svg>

        </screen-link>
        <div>OPTIONS</div>
        <div class="option-link">Import</div>
        <div class="option-link">
          <screen-link left="0%" top="0%" width="90%" height="100%" dot-class="dot-right-center" data-tgt="invite" @click.native="showDetails">
            <span class="link-text">Invite Unregistered Users</span>
          </screen-link>

        </div>
        <div class="option-link">Refresh</div>
      </div>
      <div class="add-panel" v-if="showAdd">
        <div>ADD</div>
        <div class="option-link">
          <screen-link left="0%" top="0%" width="90%" height="100%" dot-class="dot-right-center" data-tgt="add-employee" @click.native="addEmployee">
            <span class="link-text">Employee (w-2)</span>
          </screen-link>

        </div>
        <div class="option-link">Individual Contractor (1099)</div>
        <div class="option-link">Company Contractor</div>
        <div class="option-link">User</div>

      </div>
    </div>

    <div class="people-scroll">
      <div v-if="detailPanel == 'add-employee'">
        <img class="w-full" src="/img/screens/pullout-people-add-employee-state.png?v=10122022" />
      </div>
      <div class="">
        <img class="w-full" src="/img/screens/people/pullout-people-list.png?v=10122022" />
        <div class="links" v-if="detailPanel !== 'add-employee'">



          <screen-link left="5%" top="6%" width="65%" height="8%" data-tgt="profile" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        </div>
      </div>
    </div>

  </div>

  <people-pullout-profile-panel class="profile-panel" :data-primary="!showList" v-if="detailPanel" :detail-panel="detailPanel" @link="$screens.onLinkClick" @external="$screens.showExternalPage" @change="showDetails" @close="closeProfile"/>

</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
//import CompanyTabStatic from "@/components/CompanyTabStatic";
import PeoplePulloutProfilePanel from "@/components/PeoplePulloutProfilePanel";
import CompanyTabPeople from "@/components/CompanyTabPeople";
export default {
    name: "people-pullout",
  components: {CompanyTabPeople, PeoplePulloutProfilePanel, ScreenLink},
  props: {
      showList: {
        type: Boolean,
        default: true
      }
  },
    data: function(){
        return {
          detailPanel: null,
          showOptions: false,
          showAdd: false,
          addedDeposit: false,
          blah: false
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      closeProfile: function(){


         if(this.showList){
           this.showDetails({
             target:{
               dataset: {
                 tgt: ""
               }
             }
           })
         }else{
           this.$emit('close');
         }
      },

      showDetails: function(e){
        this.showOptions = false;
        this.showAdd = false;

        if((e.target.dataset.tgt === 'pay-deposit') && this.addedDeposit){
          this.detailPanel = 'pay-deposit-hsbc-added'
        }else{
          this.detailPanel = e.target.dataset.tgt;
          let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        }

        if(this.detailPanel == "pay-deposit-hsbc-added"){
          this.addedDeposit = true;
        }

      },

      addEmployee: function(e){
        this.$store.state.onboardingType = "enterprise"
        this.showDetails(e)
      },

      showDetailsOld: function(e){
        this.showOptions = false;
        this.showAdd = false;

        if((e.target.dataset.tgt === 'pay-deposit') && this.addedDeposit){
          this.detailPanel = 'pay-deposit-hsbc-added'
        }else{
          this.detailPanel = e.target.dataset.tgt;
          let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        }


          if(this.$refs.profileDetails){
            this.$refs.profileDetails.scrollTo(0,0);
          }

          if(this.detailPanel == "pay-deposit-hsbc-added"){
            this.addedDeposit = true;
          }

      },

    },
    computed: {}
}
</script>

<style scoped>


.people-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);


}

.people-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.people-pullout img {
  display: block;
}

.people-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.people-list {

  position: absolute;
  width: calc((320/1580) * 100%);
  height: 100%;


  right: 0;
  display: flex;
  flex-direction: column;

}

.people-scroll {
  position: relative;

  overflow-y: scroll;
}

.add-panel {
  position: absolute;
  background: white;
  z-index: 20;
  padding: 1em;
  box-sizing: border-box;
  top: 2em;
  left: 1%;
  width: 97%;
  margin-top: 1em;
  box-shadow: 1px 2px 5px rgba(0,0,0,0.4);
}

.options-panel {
  position: absolute;
  background: white;
  z-index: 20;
  padding: 1em;
  box-sizing: border-box;
  top: 0;
  right: 3%;
  width: 97%;
  margin-top: 1em;
  box-shadow: 1px 2px 5px rgba(0,0,0,0.4);
}

.option-link {
  position: relative;
  height: 1.25em;
  margin: 1em 0;
}

.link-text {
  pointer-events: none;
  user-select: none;
}


.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.w-full {
  width: 100%;
}

.profile-panel {
  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  right: calc((320/1580) * 100%);
  height: 100%;
  top: 0;
  bottom: 0;

}

.profile-panel[data-primary = "true"] {

  right: 0;


}



</style>
