<template>
  <div class="user-alert">
    <div class="label-header">
      <svg xmlns="http://www.w3.org/2000/svg" width="22.108" height="22.108" viewBox="0 0 22.108 22.108"><path class="a" d="M11.054,0A11.054,11.054,0,1,1,0,11.054,11.055,11.055,0,0,1,11.054,0Zm0,2.211A8.843,8.843,0,1,0,19.9,11.054,8.853,8.853,0,0,0,11.054,2.211ZM12.16,8.843v8.843H9.949V8.843Zm0-4.422V6.632H9.949V4.422Z"/></svg>
      <p  v-if="label" class="label">{{ label }}</p>
    </div>
    <slot></slot>

  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  props: {
    label: {
      default: "Coming Soon"
    },
    color: {

    }
  }
}
</script>

<style scoped>

.user-alert {

  position: fixed;
  bottom: 2%;
  right: 0%;
  border-radius: 3em 0 0 3em;
  box-sizing: border-box;
  padding: 1em 2em 1em 1.5em;

  background-color: var(--color-green);
  color: white;
  z-index: 100;

}

.user-alert svg {
  fill: white;
  width: 1.5em;
  height: 1.5em;
}

.label-header {
  display: flex;
  align-items: center;
}


.label {
  margin-left: 0.75em;
  max-width: 50ch;
}


</style>