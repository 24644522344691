<template>
  <div class="company-tab" :style="tabStyle">
    <div class="images">
      <img class="tab-image" :src="tabImage" />
    </div>
  </div>
</template>

<script>



export default {
  name: "company-tab-static",
  components: {},
  props: {
    company: {
      type: String,
      default: "enterprise"
    },
    variant: {
      type: String,
      default: "flex"
    },
    showLinks: {
      type: Boolean,
      default: true
    },

    activeCompanies: {
      type: Object,
      default: function() {
        return {
          enterprise: true,
          pro: true,
          select: true,
          peo: true,
          initech: true,
        }
      }
    },
    selectTgt: {
      type: String,
      default: ""
    },
    left: {
      type: String,
      default: "14.7%"
    },
    width: {
      type: String,
      default: "20%"
    },
    height: {
      type: String,
      default: "5%"
    },
    top: {
      type: String,
      default: "7.2%"
    }
  },
  computed: {
    tabStyle: function(){
      return {
        top: this.top,
        left: this.left,
        width: this.width
      }
    },
    tabImage: function(){
      if(this.variant === 'hiring'){
        switch (this.company){
          case "enterprise":
            return "/img/screens/hiring/company-select-acme-hiring.png"
          case "pro":
            return "/img/screens/hiring/company-select-polarson-hiring.png"
          case "select":
            return "/img/screens/hiring/company-select-irish-hiring.png"
          case "initech":
            return "/img/screens/hiring/company-select-initech-hiring.png"
          case "peo":
            return "/img/screens/hiring/company-select-peo-hiring.png"
          default:
            return "/img/screens/hiring/company-select-acme-hiring.png"
        }
      }else{
        switch (this.company){
          case "enterprise":
            return "/img/screens/dashboard/company-select-acme.png"
          case "pro":
            return "/img/screens/dashboard/company-select-polarson.png"
          case "select":
            return "/img/screens/dashboard/company-select-irish.png"
          case "initech":
            return "/img/screens/dashboard/company-select-initech.png"
          case "peo":
            return "/img/screens/dashboard/company-select-peo.png"
          default:
            return "/img/screens/dashboard/company-select-acme.png"
        }
      }

    },
    linkWidth: function(){
      if(this.company === 'initech'){
        return "87%"
      }
      return "72%";

    }
  }
}
</script>

<style scoped>
  .company-tab {
    position: absolute;

  }

  .images {
    overflow: hidden;
      position: relative;
      padding-bottom: calc((46/316) * 100%);
      background-color: white;
  }

  .tab-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>