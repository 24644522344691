


<template>
<div class="submit-issue-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="submit-issue-list">


    <div class="submit-issue-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/pullout-submit-issue.png?v=10122022" />
      <div class="links">
        <screen-link left="67.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="50%" top="30%" width="44%" height="13.5%" data-tgt="question" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="submit-issue-scroll" v-if="detailPanel === 'question'">
      <img class="w-full" src="/img/screens/pullout-submit-issue-questions.png?v=10122022" />
      <div class="links">

        <screen-link left="67.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="67%" top="122.5%" width="27%" height="5%" data-tgt="document" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="submit-issue-scroll" v-if="detailPanel === 'document'">
      <img class="w-full" src="/img/screens/pullout-submit-issue-document.png?v=10122022" />
      <div class="links">
        <screen-link left="67.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "submit-issue-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.submit-issue-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.submit-issue-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.submit-issue-pullout img {
  display: block;
}

.submit-issue-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.submit-issue-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.submit-issue-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
