<template>
  <div class="company-select">
    <img src="/img/screens/hiring/admin-onboarding-company-select.png?v=10122022" />
    <screen-link v-if="activeCompanies.enterprise" left="3%" top="52%" width="90%" height="20%" dot-class="dot-right-center" data-tgt="hiring-v2-home" @click.native="setCompany('enterprise')" />
    <screen-link v-if="activeCompanies.peo" left="3%" top="74%" width="90%" height="20%" dot-class="dot-right-center" data-tgt="hiring-peo" @click.native="setCompany('peo')" />
  </div>
</template>

<script>
export default {
  name: "company-select-onboarding",
  props: {
    triggerCode: {
      type: String,
      default: ""
    },
    activeCompanies: {
      type: Object,
      default: function() {
        return {
          enterprise: true,
          pro: false,
          select: false,
          peo: true,
          initech: false,
        }
      }
    }
  },
  methods: {
    setCompany: function(company){
      this.$emit('select',company)
      //this.$screens.toggleOverlay();
    }
  }
}
</script>

<style scoped>

  .company-select {
    background-color: white;
    position: absolute;
    top: calc(100% + 0.5em);
    box-shadow: rgba(0,0,0,0.2) 0 0 10px;

  }

  .company-select img {
    width: calc((320 / 1580) * 100 * 1em);
  }

</style>