<template>
  <div class="flock-screens-container">
    <div class="flock-screens" ref="screens">


      <div class="screen" data-screen="flock-home" :data-active="currentScreen=='flock-home'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-home.png?v=10122022" />
        <div class="links">
          <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
            <p>Health & Benefits allows a seamless integration of benefits with payroll and the benefit of increased data accuracy, less risk of reporting or calculation errors, reduced administrative activities for administrators, and a single employee record for data storage.</p>
          </info-box>
        </div>
      </div>

      <div class="screen" data-screen="flock-tasks" :data-active="currentScreen=='flock-tasks'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-tasks.png?v=10122022" />
        <div class="links">
        </div>
      </div>

      <div class="screen" data-screen="flock-tasks-employee" :data-active="currentScreen=='flock-tasks-employee'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-tasks-employee.png?v=10122022" />
        <div class="links">
        </div>
      </div>

      <div class="screen" data-screen="flock-people" :data-active="currentScreen=='flock-people'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-people.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="22%" top="30%" width="72%" height="6%" data-tgt="flock-profile" @click.native="onLinkClick"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="flock-benefits" :data-active="currentScreen=='flock-benefits'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-benefits-employees.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="21%" top="12.5%" width="77%" height="5.5%" data-tgt="flock-benefits-employee-select" @click.native="onLinkClick"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="flock-benefits-employee-select" :data-active="currentScreen=='flock-benefits-employee-select'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-benefits-employees-select.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="21%" top="12.5%" width="77%" height="5.5%" data-tgt="flock-benefits" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="22%" top="33%" width="75%" height="5.5%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
        </div>
      </div>

      <div class="screen" data-screen="flock-benefits-andrea" :data-active="currentScreen=='flock-benefits-andrea'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-benefits-andrea.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-top-center" left="85.7%" top="48%" width="9%" height="6%" data-tgt="flock-enrollment" @click.native="onLinkClick"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="flock-plans" :data-active="currentScreen=='flock-plans'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-plans.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-top-center" left="28%" top="10%" width="7%" height="6%" data-tgt="flock-plans" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="35.5%" top="10%" width="6%" height="6%" data-tgt="flock-plans-nextyear" @click.native="onLinkClick"></screen-link>


          <screen-link dot-class="dot-top-center" left="82.5%" top="59%" width="7%" height="4%" data-tgt="/img/screens/flock/UHC_HDHP.pdf" @click.native="$screens.showExternalPage"></screen-link>
          <screen-link dot-class="dot-right-center" left="92.5%" top="58.5%" width="3%" height="5%" data-tgt="flock-enrollment" @click.native="toggleOverlayPanel('flock-plan-options')"></screen-link>

          <div v-if="$store.state.overlayPanel === 'flock-plan-options'" style="top:63%;right:4%;" class="options-overlay">
            <div class="option app-link" data-show-dot="true" data-tgt="flock-plan-summary" @click="onLinkClick">View Plan Summary</div>
            <div class="option app-link" data-show-dot="true" data-tgt="flock-plan-enrolled" @click="onLinkClick">View Enrolled Employees</div>
          </div>

        </div>
      </div>

      <div class="screen" data-screen="flock-plans-nextyear" :data-active="currentScreen=='flock-plans-nextyear'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-plans-nextyear.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-top-center" left="28%" top="10%" width="7%" height="6%" data-tgt="flock-plans" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="35.5%" top="10%" width="6%" height="6%" data-tgt="flock-plans-nextyear" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-right-center" left="39%" top="36.5%" width="7%" height="6%" data-tgt="flock-plans-nextyear-notstarted" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-top-center" left="82.5%" top="89.5%" width="7%" height="4%" data-tgt="/img/screens/flock/UHC_HDHP.pdf" @click.native="$screens.showExternalPage"></screen-link>


        </div>
      </div>

      <div class="screen" data-screen="flock-plans-nextyear-notstarted" :data-active="currentScreen=='flock-plans-nextyear-notstarted'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-plans-nextyear-notstarted.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-top-center" left="68%" top="1.5%" width="3.5%" height="5%" data-tgt="flock-plans-nextyear" @click.native="onLinkClick"></screen-link>



        </div>
      </div>

      <div class="screen" data-screen="flock-plan-enrolled" :data-active="currentScreen=='flock-plan-enrolled'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-plans-options-enrolled.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="97%" top="1.5%" width="3%" height="5%" data-tgt="flock-plans" @click.native="onLinkClick"></screen-link>
        </div>
      </div>

      <div class="screen" data-screen="flock-plan-summary" :data-active="currentScreen=='flock-plan-summary'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-plans-options-summary.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="97%" top="1.5%" width="3%" height="5%" data-tgt="flock-plans" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="22.25%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="35%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-configuration" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="50%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-rates" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="65%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-docs" @click.native="onLinkClick"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="flock-plan-configuration" :data-active="currentScreen=='flock-plan-summary-configuration'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-plans-options-summary-config.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="97%" top="1.5%" width="3%" height="5%" data-tgt="flock-plans" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="22.25%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="35%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-configuration" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="50%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-rates" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="65%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-docs" @click.native="onLinkClick"></screen-link>

        </div>
      </div>
      <div class="screen" data-screen="flock-plan-rates" :data-active="currentScreen=='flock-plan-summary-rates'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-plans-options-summary-rates.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="97%" top="1.5%" width="3%" height="5%" data-tgt="flock-plans" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="22.25%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="35%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-configuration" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="50%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-rates" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="65%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-docs" @click.native="onLinkClick"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="flock-plan-docs" :data-active="currentScreen=='flock-plan-summary-docs'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-plans-options-summary-docs.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="97%" top="1.5%" width="3%" height="5%" data-tgt="flock-plans" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="22.25%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="35%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-configuration" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="50%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-rates" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="65%" top="14.5%" width="12%" height="5%" data-tgt="flock-plan-summary-docs" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="41.5%" top="24%" width="17%" height="7%" data-tgt="/img/screens/flock/UHC_HDHP.pdf" @click.native="$screens.showExternalPage"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="flock-events" :data-active="currentScreen=='flock-events'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-benefits-pending.png?v=10122022" />
        <div class="links">

        </div>
      </div>

      <div class="screen" data-screen="flock-reports" :data-active="currentScreen=='flock-reports'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-reports.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="93.25%" top="35.75%" width="3%" height="5%" data-tgt="" @click.native="toggleOverlayPanel('flock-report-options')"></screen-link>

          <div v-if="$store.state.overlayPanel === 'flock-report-options'" style="top:40.5%;right:3.5%;width:12em;" class="options-overlay">
            <div class="option app-link" data-show-dot="true" data-tgt="flock-reports-schedule" @click="onLinkClick">Schedule</div>
          </div>
        </div>
      </div>

      <div class="screen" data-screen="flock-reports-schedule" :data-active="currentScreen=='flock-reports-schedule'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-reports-options-schedule.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="68%" top="30%" width="3%" height="4%" data-tgt="flock-reports" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="29%" top="43.5%" width="42%" height="4.5%" data-tgt="flock-reports-schedule-recipient" @click.native="onLinkClick"/>
        </div>
      </div>

      <div class="screen" data-screen="flock-reports-schedule-recipient" :data-active="currentScreen=='flock-reports-schedule-recipient'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-reports-options-schedule-recipient.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="68%" top="30%" width="3%" height="4%" data-tgt="flock-reports" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="29%" top="52%" width="42%" height="4.5%" data-tgt="flock-reports-schedule-recipient-selected" @click.native="onLinkClick"/>
        </div>
      </div>

      <div class="screen" data-screen="flock-reports-schedule-recipient-selected" :data-active="currentScreen=='flock-reports-schedule-recipient-selected'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-reports-options-schedule-recipient-selected.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="68%" top="30%" width="3%" height="4%" data-tgt="flock-reports" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="33.5%" top="53%" width="5%" height="4%" data-tgt="flock-reports-schedule-date" @click.native="onLinkClick"/>
        </div>
      </div>

      <div class="screen" data-screen="flock-reports-schedule-date" :data-active="currentScreen=='flock-reports-schedule-date'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-reports-options-schedule-date.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="68%" top="30%" width="3%" height="4%" data-tgt="flock-reports" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-top-center" left="66%" top="61%" width="4.5%" height="5%" data-tgt="flock-reports" @click.native="onLinkClick"/>
        </div>
      </div>

      <div class="screen" data-screen="flock-documents" :data-active="currentScreen=='flock-documents'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-documents.png?v=10122022" />
        <div class="links">

        </div>
      </div>

      <div class="screen" data-screen="flock-settings" :data-active="currentScreen=='flock-settings'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-settings.png?v=10122022" />
        <div class="links">

        </div>
      </div>

      <div class="screen" data-screen="flock-settings-people" :data-active="currentScreen=='flock-settings-people'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-settings-people-notifications.png?v=10122022" />
        <div class="links">

        </div>
      </div>

      <div class="screen" data-screen="flock-settings-benefits" :data-active="currentScreen=='flock-settings-benefits'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-settings-benefits-notifications.png?v=10122022" />
        <div class="links">

        </div>
      </div>

      <div class="screen" data-screen="flock-settings-roles" :data-active="currentScreen=='flock-settings-roles'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-settings-roles.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-top-center" left="89%" top="6%" width="9%" height="5.5%" data-tgt="flock-roles-create" @click.native="onLinkClick"/>

        </div>
      </div>

      <div class="screen" data-screen="flock-roles-create" :data-active="currentScreen=='flock-roles-create'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-settings-roles-create.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-top-center" left="97%" top="1%" width="3%" height="5.5%" data-tgt="flock-settings-roles" @click.native="onLinkClick"/>

        </div>
      </div>

      <div class="screen" data-screen="flock-profile" :data-active="currentScreen=='flock-profile'">
        <img class="w-full max-w-100" src="/img/screens/flock/flock-admin-profile.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="32%" top="20%" width="9%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment" :data-active="currentScreen=='flock-enrollment'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-right-center" left="44%" top="145%" width="9%" height="5%" data-tgt="flock-enrollment-nextyear" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear" :data-active="currentScreen=='flock-enrollment-nextyear'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-right-center" left="71%" top="92.5%" width="10%" height="5.5%" data-tgt="flock-enrollment-nextyear-dependents" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-dependents" :data-active="currentScreen=='flock-enrollment-nextyear-dependents'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-dependents.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-right-center" left="71%" top="9.5%" width="10%" height="5.5%" data-tgt="flock-enrollment-nextyear-dependents-add" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-dependents-add" :data-active="currentScreen=='flock-enrollment-nextyear-dependents-add'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-dependents-add.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="29%" top="15.5%" width="20%" height="5.5%" data-tgt="flock-enrollment-nextyear-dependents-add-details" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-dependents-add-details" :data-active="currentScreen=='flock-enrollment-nextyear-dependents-add-details'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-dependents-add-details.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-right-center" left="65%" top="89%" width="6%" height="5.5%" data-tgt="flock-enrollment-nextyear-dependents-added" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-dependents-added" :data-active="currentScreen=='flock-enrollment-nextyear-dependents-added'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-dependents-added.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-right-center" left="71%" top="43%" width="10%" height="5.5%" data-tgt="flock-enrollment-nextyear-medical" data-tgt-scrolltop="true" @click.native="onLinkClick" />

          <screen-link dot-class="dot-left-center" left="16%" top="43%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-medical" :data-active="currentScreen=='flock-enrollment-nextyear-medical'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-medical.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="16%" top="42%" width="22%" height="8%" data-tgt="flock-enrollment-nextyear-medical-wdep"  @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-medical-wdep" :data-active="currentScreen=='flock-enrollment-nextyear-medical-wdep'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-medical-wdep.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="16%" top="42%" width="22%" height="8%" data-tgt="flock-enrollment-nextyear-medical"  @click.native="onLinkClick" />
          <screen-link dot-class="dot-left-center" left="71%" top="49%" width="10%" height="8%" data-tgt="flock-enrollment-nextyear-medical-compare" data-tgt-scrolltop="true" @click.native="onLinkClick" />


          <screen-link dot-class="dot-right-center" left="16%" top="57%" width="64.5%" height="30%" data-tgt="flock-enrollment-nextyear-medical-selected" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-medical-compare" :data-active="currentScreen=='flock-enrollment-nextyear-medical-compare'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-medical-compare.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="68%" top="21%" width="3%" height="4%" data-tgt="flock-enrollment-nextyear-medical-wdep" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="29%" top="28%" width="42%" height="8%" data-tgt="flock-enrollment-nextyear-medical-compare-choices"  @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-medical-compare-choices" :data-active="currentScreen=='flock-enrollment-nextyear-medical-compare-choices'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-medical-compare-choices.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="68%" top="21%" width="3%" height="4%" data-tgt="flock-enrollment-nextyear-medical-wdep" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="61.5%" top="73%" width="9.5%" height="5.5%" data-tgt="flock-enrollment-nextyear-medical-compare-table"  @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-medical-compare-table" :data-active="currentScreen=='flock-enrollment-nextyear-medical-compare-table'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-medical-compare-table.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="79%" top="4%" width="2.5%" height="4%" data-tgt="flock-enrollment-nextyear-medical-wdep" @click.native="onLinkClick"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-medical-selected" :data-active="currentScreen=='flock-enrollment-nextyear-medical-selected'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-medical-selected.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="71%" top="49%" width="10%" height="8%" data-tgt="flock-enrollment-nextyear-medical-compare" data-tgt-scrolltop="true" @click.native="onLinkClick" />


          <screen-link dot-class="dot-left-center" left="16%" top="194%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-dependents-added" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="71%" top="194%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-dental" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-dental" :data-active="currentScreen=='flock-enrollment-nextyear-dental'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-dental.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-left-center" left="16%" top="42%" width="22%" height="8%" data-tgt="flock-enrollment-nextyear-dental-wdep"  @click.native="onLinkClick" />

          <screen-link dot-class="dot-left-center" left="16%" top="130%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-medical-selected" data-tgt-scrolltop="true" @click.native="onLinkClick" />




        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-dental-wdep" :data-active="currentScreen=='flock-enrollment-nextyear-dental-wdep'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-dental-wdep.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>


          <screen-link dot-class="dot-right-center" left="16%" top="89%" width="64.5%" height="30%" data-tgt="flock-enrollment-nextyear-dental-selected" @click.native="onLinkClick" />

          <screen-link dot-class="dot-left-center" left="16%" top="130%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-medical-selected" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="71%" top="130%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-medical-compare" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-dental-selected" :data-active="currentScreen=='flock-enrollment-nextyear-dental-selected'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-dental-selected.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="16%" top="130%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-medical-selected" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="71%" top="130%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-vision" :data-active="currentScreen=='flock-enrollment-nextyear-vision'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-vision.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-right-center" left="71%" top="116%" width="9%" height="6%" data-tgt="flock-enrollment-nextyear-vision-waive" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-left-center" left="16%" top="125.5%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-dental-selected" data-tgt-scrolltop="true" @click.native="onLinkClick" />


        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-vision-waive" :data-active="currentScreen=='flock-enrollment-nextyear-vision-waive'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-vision-waive.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-right-center" left="68%" top="3%" width="3%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="40%" top="16.5%" width="15%" height="6%" data-tgt="flock-enrollment-nextyear-vision-waive-options" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="65%" top="27%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-fsa" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-vision-waive-options" :data-active="currentScreen=='flock-enrollment-nextyear-vision-waive-options'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-vision-waive-options.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-right-center" left="68%" top="3%" width="3%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-left-center" left="41%" top="37.5%" width="9%" height="3.5%" data-tgt="flock-enrollment-nextyear-vision-waive-not-interested" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="65%" top="27%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-fsa" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-vision-waive-not-interested" :data-active="currentScreen=='flock-enrollment-nextyear-vision-waive-not-interested'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-vision-waive-not-interested.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-right-center" left="68%" top="3%" width="3%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" @click.native="onLinkClick" />

          <screen-link dot-class="dot-right-center" left="65%" top="27%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-fsa" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-fsa" :data-active="currentScreen=='flock-enrollment-nextyear-fsa'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-fsa.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-right-center" left="75%" top="37%" width="5%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-fsa-dependent" :data-active="currentScreen=='flock-enrollment-nextyear-fsa-dependent'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-fsa-dependent.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="16%" top="105.5%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="65%" top="96%" width="15%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent-waive" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-fsa-dependent-waive" :data-active="currentScreen=='flock-enrollment-nextyear-fsa-dependent-waive'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-fsa-dependent-waive.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="68%" top="3%" width="3%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent" data-tgt-scrolltop="true" @click.native="onLinkClick" />

          <screen-link dot-class="dot-right-center" left="65%" top="18.5%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-hsa" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-hsa" :data-active="currentScreen=='flock-enrollment-nextyear-hsa'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-hsa.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-right-center" left="67%" top="49.5%" width="12%" height="5%" data-tgt="flock-enrollment-nextyear-hsa-1000"  @click.native="onLinkClick" />
          <screen-link dot-class="dot-left-center" left="16%" top="97%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent" data-tgt-scrolltop="true" @click.native="onLinkClick" />


        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-hsa-1000" :data-active="currentScreen=='flock-enrollment-nextyear-hsa-1000'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-hsa-1000.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-right-center" left="71%" top="97%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-life" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-left-center" left="16%" top="97%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-life" :data-active="currentScreen=='flock-enrollment-nextyear-life'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-life.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-right-center" left="16%" top="38%" width="64%" height="50%" data-tgt="flock-enrollment-nextyear-life-selected"  @click.native="onLinkClick" />
          <screen-link dot-class="dot-left-center" left="16%" top="109%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-hsa-1000" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-life-selected" :data-active="currentScreen=='flock-enrollment-nextyear-life-selected'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-life-selected.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="16%" top="109%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-hsa-1000" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="71%" top="109%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-disability" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-disability" :data-active="currentScreen=='flock-enrollment-nextyear-disability'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-disability.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="16%" top="100%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-life-selected" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="63%" top="91%" width="17%" height="6%" data-tgt="flock-enrollment-nextyear-disability-waived" data-tgt-scrolltop="true" @click.native="onLinkClick" />
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-disability-waived" :data-active="currentScreen=='flock-enrollment-nextyear-disability-waived'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-disability-waived.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-left-center" left="59%" top="19%" width="6%" height="5%" data-tgt="flock-enrollment-nextyear-disability" @click.native="onLinkClick"></screen-link>
          <screen-link dot-class="dot-right-center" left="65%" top="19%" width="6%" height="5%" data-tgt="flock-enrollment-nextyear-legal" @click.native="onLinkClick"></screen-link>
        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-legal" :data-active="currentScreen=='flock-enrollment-nextyear-legal'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-legal.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="16%" top="53%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-disability" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="71%" top="53%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-beneficiary" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-beneficiary" :data-active="currentScreen=='flock-enrollment-nextyear-beneficiary'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-beneficiary.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="16%" top="97%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-legal" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-left-center" left="66.5%" top="97%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-review" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-review" :data-active="currentScreen=='flock-enrollment-nextyear-review'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-review.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-andrea" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="16%" top="329%" width="6%" height="6%" data-tgt="flock-enrollment-nextyear-beneficiary" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="70%" top="329%" width="11%" height="6%" data-tgt="flock-enrollment-nextyear-success" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-enrollment-nextyear-success" :data-active="currentScreen=='flock-enrollment-nextyear-success'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-success.png?v=10122022" />
        <div class="links">
          <screen-link dot-class="dot-right-center" left="95.5%" top="2.5%" width="3%" height="4%" data-tgt="flock-benefits-summary" @click.native="onLinkClick"></screen-link>

          <screen-link dot-class="dot-left-center" left="45%" top="35%" width="10%" height="6%" data-tgt="flock-home" data-tgt-scrolltop="true" @click.native="onLinkClick" />
          <screen-link dot-class="dot-right-center" left="42.5%" top="25.5%" width="15%" height="6%" data-tgt="flock-benefits-summary" data-tgt-scrolltop="true" @click.native="onLinkClick" />

        </div>
      </div>

      <div class="screen" data-screen="flock-benefits-summary" :data-active="currentScreen=='flock-benefits-summary'">
        <img class="w-full max-w-100" src="/img/screens/flock/enrollment/flock-benefits-enrollment-nextyear-summary.png?v=10122022" />
        <div class="links">

        </div>
      </div>





    </div>

    <FlockNavigation :current-screen="currentScreen" />

  </div>
</template>

<script>

import FlockNavigation from "@/components/flock/FlockNavigation";
export default {
  name: "AdminFlock",
  components: {FlockNavigation},
  props: {
    currentScreen: { default: "flock-home"}
  },
  methods: {
    onLinkClick: function(e){
      this.$screens.onLinkClick(e);
      if(e.target.dataset.tgtScrolltop){
        this.$refs['screens'].scrollTo(0,0)
      }

    },

    toggleOverlayPanel: function(tgt){
      if(this.$store.state.overlayPanel === tgt){
        this.$screens.setOverlayPanel();
      }else{
        this.$screens.setOverlayPanel(tgt);
      }
    }
  }
}
</script>

<style scoped>

.flock-screens-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
  background: white;
  z-index:50;
}

.flock-screens {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  overflow-y: scroll;
}

.options-overlay {
  position: absolute;

  background: white;
  border: 1px solid #aaa;
  border-radius: 0.3em;
  font-size: 0.9em;
  width: 15em;
  box-shadow: 0 0.5em 1em rgba(0,0,0,0.1);
}

.options-overlay .option {
  padding: 0.75em;
  border-bottom: 1px solid #aaa;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.options-overlay .option:hover {
  background-color: #f0f0f0;
}

.options-overlay .option:last-of-type {
  border-bottom: none;
}


</style>