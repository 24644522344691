


<template>
<div class="help-pullout pullout-panel">

  <div class="help-list">


    <div class="help-scroll" v-if="$store.state.helpPanel === 'help'">
      <img class="w-full" src="/img/screens/help/help-topics.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'pay-raise'">
      <img class="w-full" src="/img/screens/help/help-pay-raise.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'rehired'">
      <img class="w-full" src="/img/screens/help/help-rehired.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>
    <div class="help-scroll" v-if="$store.state.helpPanel === 'commute'">
      <img class="w-full" src="/img/screens/help/help-commute.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'low-pay'">
      <img class="w-full" src="/img/screens/help/help-low-pay.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'payroll'">
      <img class="w-full" src="/img/screens/help/help-payroll.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'length-of-service'">
      <img class="w-full" src="/img/screens/help/help-length-of-service.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'hourly-work'">
      <img class="w-full" src="/img/screens/help/help-hourly-work.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'rebalance'">
      <img class="w-full" src="/img/screens/help/help-rebalance.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'prior-payroll'">
      <img class="w-full" src="/img/screens/help/help-prior-payroll.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="help-scroll" v-if="$store.state.helpPanel === 'tax-status'">
      <img class="w-full" src="/img/screens/help/help-tax-info-short.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "help-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.help-pullout {
  position: absolute;
  top: 0;
  right:0;
  height: 100%;
  z-index: 100;
  width: calc((320/1580) * 100%);

}

.help-pullout img {
  display: block;
}


.help-list {

  position: absolute;
  width: 100%;
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;



}



.help-scroll {
  position: relative;

  overflow-y: scroll;

}

.w-full {
  width: 100%;
}

.links {

}

</style>
