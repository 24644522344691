<template>

        <div class="header-nav" :data-offset="offset">

            <div class="logo-container">
                <svg class="logo" viewBox="0 0 218.18 22" id="paychexbeta_logo" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M20.77 7.26c0-4.65-2.46-6.76-6.24-6.76H4.3L0 21.5h6.27l1.3-6.34h4.28c5.42.01 8.92-3.64 8.92-7.9zM11 10.16H8.61l.89-4.29h2.16c1.33 0 2.75.27 2.75 1.89 0 1.43-.83 2.4-3.41 2.4zM26.79.51l-11.92 21h6.47l1.59-3h6.73l.35 3h6.23L33 .51zM25.23 14l3.21-6.29h.06l.69 6.29h-4zM48.93.51l-5 7.82-2-7.82h-6.4L40 13.8l-1.56 7.7h6.35l1.56-7.76 10-13.23zm17 13c-.4 1.85-1.53 3.23-3.52 3.23-2.92 0-3.33-2.7-3.33-4.44 0-3 1.27-7.07 4.8-7.07 2 0 2.89 1.12 2.83 3.08h6C72.55 2 68.45 0 63.85 0c-7 0-11.19 5.66-11.19 12.44 0 5.56 2.89 9.56 8.87 9.56 8.58 0 10.2-6.34 10.58-8.48h-6.18zm22.63-13L87 7.86h-5.8L82.7.5h-6.32l-4.3 21h6.35l1.7-8.27h5.8l-1.7 8.27h6.35l4.35-21h-6.37zm38.24 0L122.68 6l-2-5.47H97.1l-4.31 21h23.76l4.76-5.87 2.28 5.87h7.13l-5-11.14L134.3.53h-7.54zm-16.31 19l.7-3.42h-10.94l.55-2.66h9.94l1-5h-9.91l.55-2.61h10.69L114 1l4.48 9.35zm38.14-10.27l1-4.85h7.78l3.37-3.88h-14.92l-4.44 21H146l1.85-8.67h6.26l3.1-3.59h-8.58zm13.92 8.38L166.14.5h-4.64l-4.41 21h12.26l3.32-3.88zm15.47 0l1.09-5.15 6.35-.07 3-3.52h-8.63l.94-4.5h7.8L191.82.5H177l-4.41 21h12.7l3.36-3.88h-10.63zM211.29.51h-5.47l-5.38 6.94-3-6.94h-5l4.91 9.94-9.56 11.05h5.59l6-7.5 3.29 7.5h5.09l-5.2-10.85zM215.18 0a3 3 0 0 0-3 3.07 3 3 0 0 0 3 3.07 3 3 0 0 0 3-3.07 3 3 0 0 0-3-3.07zm0 5.65a2.52 2.52 0 0 1-2.53-2.59 2.53 2.53 0 1 1 5.05 0 2.45 2.45 0 0 1-2.52 2.59zm1.38-3.43c0-.6-.36-1-1.14-1h-1.32v3.59h.42V3.25h.72l1 1.56h.48l-1-1.56c.48-.12.84-.36.84-1.02zm-1.44.6h-.54v-1.2h.78c.42 0 .84.12.84.6s-.54.61-1.08.61z" ></path></svg>
            </div>

            <div class="spacer"></div>
            <div class="top-menu-right visible">

                <!-- Help Icon -->
                <!---->


                <div class="icon-container people-icon app-link dot-bottom-center" :class="{disabled:(appMode!='admin')}" data-show-dot="true" @click="onButtonClick('pullout:people')">
                    <div class="md-icon">
                        <svg data-name="Layer 1" viewBox="0 0 40 40" id="people_header" xmlns="http://www.w3.org/2000/svg" fit=""  preserveAspectRatio="xMidYMid meet" focusable="false"><title>people_header</title><path d="M22 14.5a1.5 1.5 0 0 1 1.5-1.5h10a1.5 1.5 0 0 1 0 3h-10a1.5 1.5 0 0 1-1.5-1.5zM33.5 25h-6a1.5 1.5 0 0 0 0 3h6a1.5 1.5 0 0 0 0-3zm0-6h-10a1.5 1.5 0 0 0 0 3h10a1.5 1.5 0 0 0 0-3zM15 20.5a4.5 4.5 0 1 0-4.5-4.5 4.49 4.49 0 0 0 4.5 4.5zm0 1.5c-5.52 0-9 2.49-9 5.65V28a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-.35c0-3.12-3.48-5.65-9-5.65z" ></path></svg>
                    </div>
                </div>

                <div class="icon-container news-icon app-link dot-bottom-center" data-show-dot="true" @click="onButtonClick('pullout:news')">
                    <div class="md-icon">
                        <svg viewBox="0 0 24 20" id="loudspeaker" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><title>loudspeaker</title><path d="M21.34.67v.46L2.68 7.6v-.25a.67.67 0 0 0-.2-.48.71.71 0 0 0-.48-.19H.68a.71.71 0 0 0-.48.19.67.67 0 0 0-.2.47v5.33a.67.67 0 0 0 .68.67H2a.68.68 0 0 0 .68-.68v-.09l2.65 1V16a4 4 0 0 0 4 4 3.75 3.75 0 0 0 4-3.79l8 2.66v.46A.68.68 0 0 0 22 20h1.32a.67.67 0 0 0 .67-.66V.67a.68.68 0 0 0-.66-.67H22a.68.68 0 0 0-.66.67zM11 15.88A2 2 0 0 1 9 18a2.26 2.26 0 0 1-2-2.36V14l4 1.32z"></path></svg>
                    </div>
                </div>

                <div class="icon-container message-icon app-link dot-bottom-center" data-show-dot="true" @click="onButtonClick('pullout:notifications')">
                    <div class="md-icon">
                        <svg viewBox="0 0 22 18" id="message-icon" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M21.51 6.13l-10 9.38a.72.72 0 0 1-1 0l-10-9.38C.22 5.88 0 6 0 6.32v10.4A1.33 1.33 0 0 0 1.38 18h19.25A1.33 1.33 0 0 0 22 16.72V6.32c0-.32-.22-.44-.49-.19z"></path><path d="M20.63 0H1.38A1.33 1.33 0 0 0 0 1.28v.38a1.49 1.49 0 0 0 .49.83l10 9.38a.72.72 0 0 0 1 0l10-9.38a1.48 1.48 0 0 0 .51-.83v-.38A1.33 1.33 0 0 0 20.63 0z"></path></svg>
                    </div>
                </div>

                <div class="icon-container account-icon app-link dot-bottom-center" data-show-dot="true" @click="onAccountClick">
                    <div class="md-icon">
                      <img src="/img/debra-flannery-avatar.jpg" class="avatar" />


                    </div>

                    <div class="drop-down-arrow">
                        <svg viewBox="0 0 10 6" id="avatar-arrow-down-icon" xmlns="http://www.w3.org/2000/svg" fit=""  preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M0 0h10L5 6z"></path></svg>
                    </div>
                </div>

                <!-- client search icon for internal -->
                <!---->


            </div>

            <div class="account-panel" v-if="showAccountPanel">
              <div class="account-panel-header">
                <div class="md-icon">
                  <img src="/img/debra-flannery-avatar.jpg" class="avatar" />


                </div>
                <div class="account-panel-header-info">
                  <h3>Deborah Flannery</h3>
                  <div><small>{{ lastLoginText }}</small></div>
                  <div><small>{{  lastLoginDate }}</small></div>
                </div>
              </div>
              <div class="account-panel-links">
                <div class="account-panel-link profile-link" @click="onSwitchMode"><span class="account-panel-link" data-show-dot="true">{{ accountLinkText }}</span></div>
                <div v-if="appMode ==='employee'" class="account-panel-link profile-link" @click="onUserPreferences"><span class="account-panel-link" data-show-dot="true" data-tgt="preferences">{{ preferencesLinkText }}</span></div>
                <div v-else class="account-panel-link"><span>User Preferences</span></div>
                <div class="account-panel-link" @click="logout"><span>{{ logOutText }}</span></div>
              </div>
            </div>

        </div>




</template>

<script>
    export default {
        name: "flex-nav-top-bar",
        props: {
            overlayMode: {
                type: String,
                required: false,
                default: null
            },
          offset: {
              default: false
          },
            lang: {
              type: String,
              required: false,
              default: "en"
            }
        },
        data: function () {
            return {
              showAccountPanel: false,
              today: (new Date())
            }
        },
        methods: {
            onButtonClick: function(demoId){
                //this.$emit("nav-click", demoId);
              if(demoId){
                let linkParams = demoId.split(":");
                if(linkParams[0] == "pullout"){
                  this.$screens.setOverlayPanel(linkParams[1]);
                }
              }
            },

            onAccountClick: function(){

                this.showAccountPanel = !this.showAccountPanel;

            },

            onSwitchMode: function(){

              console.log(this.$route);
              if(this.$route.name == "admin"){
                this.$router.push("/employee/dashboard")
              }else{
               this.$router.push("/admin/dashboard")
              }
            },

            onUserPreferences: function(e){
              this.showAccountPanel = false;
              this.$screens.showPullout(e);
            },


            logout: function(){

              this.$store.state.showFlexLogin = true;
              this.$store.commit("login", false);
              this.$router.push("/login");
            }
        },
        computed: {

            lastLoginDate: function(){
                return this.today.toDateString() + " " + this.today.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
            },


            activeAppLink: function(){
                if(this.overlayMode){
                    return this.overlayMode;
                }

                return "dashboard";
            },

            appMode: function(){
              return this.$route.name;
            },

            isSpanish: function(){
              return (this.lang === 'es')
            },

            lastLoginText: function(){
              if(this.$route.name == "employee") {
                if(this.isSpanish){
                  return "Último acceso:";
                }else{
                  return "Last Login:";
                }

              }else{
                return "Last Login:";
              }
            },

            accountLinkText: function(){

              if(this.$route.name == "employee"){
                if(this.isSpanish){
                  return "Cambiar a cuenta de administrador";
                }else{
                  return "Switch to Administrator Account";
                }
              }else{
                return "Switch to Personal Account";
              }

            },

            preferencesLinkText: function(){
              if(this.$route.name === "employee") {
                if(this.isSpanish){
                  return "Preferencias de Usuario";
                }else{
                  return "User Preferences";
                }

              }else{
                return "User Preferences";
              }
            },

          logOutText: function(){
            if(this.$route.name === "employee") {
              if(this.isSpanish){
                return "Cerrar sesión";
              }else{
                return "Log Out";
              }

            }else{
              return "Log Out";
            }
          }


        }
    }
</script>

<style scoped>




    .header-nav {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 0;
        padding-bottom: calc((60/1580) * 100%);
        background-color: #0369b1;
        font-family: "Helvetica", "Arial", sans-serif;
        font-size: calc((17 / 19.2) * 1em);
        -webkit-font-smoothing: antialiased;
    }

    .logo-container {
        position: absolute;
        width: calc((225 / 1580) * 100%);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo-container .logo {
        position: relative;
        width: calc((180/225) * 100%);
        height: calc((20/60) * 100%);
        fill: white;

    }



    .top-menu-right {
        position: absolute;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;

        top: 0;
        right: 0;
        color: #d0e3f9;
        fill: #d0e3f9;
        padding-right: 2em;
    }

    [data-offset='true'] .top-menu-right{
      margin-right: calc((320/1520) * 100%);
    }

    .spacer {
        flex-grow: 1;
    }

    .icon-container {
        position: relative;
        height: 100%;
        box-sizing: border-box;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        cursor: pointer;
      padding: 0 1em;

    }

    .help-icon {


    }

    .md-icon {
        position: relative;
        height: 100%;
        box-sizing: border-box;
    }

    .md-icon svg {
        width: 1.5em;
        height: 100%;
        margin: 0;
    }

    .md-icon img {
      width: 100%;
      height: 100%;
      margin: 0;
    }


    .people-icon  {
        /*padding: 3.8% 4.4%;*/
      padding: 0 0.5em;
      position: relative;


    }

    .people-icon svg {
      width: 2.75em;
    }

    .message-icon {
        /*padding: 7.5% 4.4%;*/
        /*margin-right: 2%;*/
      position: relative;

    }

    .news-icon {
        /*padding: 7.5% 4.4%;*/
        /*margin-right: 2%;*/
      position: relative;

    }

    .account-icon {
        padding: 5.0% 4.4%;

        position: relative;



    }

    .account-icon .md-icon {
        width: 2em;
       display: flex;
      align-items: center;
    }

    .account-icon .md-icon img {
      width: 2em;
      height: 2em;
    }



    .account-icon:hover md-icon svg {
        fill: white;
    }

    .account-icon svg .account-icon-text {
      font-size: 18px;
      font-weight: bold;
    }

    .account-icon .drop-down-arrow {
        position: absolute;
        right: -12%;
        top: 50%;
        transform: translateY(-50%);
        fill: #d0e3f9;
        width: 20%;
        height: auto;



    }

    .icon-container.account-icon svg {



    }

    .icon-container:hover {
        color: white;
        fill: white;
    }

    .account-panel {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: white;
      padding: 1.5em;
      padding-bottom: 0;
      box-shadow: 0 1px 10px rgba(0,0,0,0.3);
      border-radius: 0.2em;
    }

    .account-panel .md-icon {
      width: 4em;
      height: 4em;
      margin-right: 1em;
    }

    .account-panel-header {
      display: flex;
      padding-bottom: 1.5em;
      padding-right: 4em;
    }

    .account-panel-header-info h3 {
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0.5em;

    }

    .account-panel-header-info small {
      color: #656565;
    }

    .profile-link {
      position: relative;
    }

    .profile-link:after {

    }


    .account-panel-link {
      padding: 1em 0;
      border-top: 1px solid #ccc;
      cursor: pointer;
    }

    .account-panel-link:hover {
      color: #0369b1;
    }

    .disabled {
      visibility: hidden;
      user-select: none;
    }

    .avatar {
      overflow: hidden;
      border-radius: 100%;
    }

    .profile-link .account-panel-link[data-show-dot="true"]:after {
      left: -1.5rem;
    }


    @media(min-width: 1580px){
      .header-nav {
        font-size: 15.8px;

      }
    }








</style>
